<template>
  <div v-if="canShowThisView">
    <top-info
      :contract="contract"
      @update-has-plan="updateHasPlan"
    />

    <div class="d-grid base-info">
      <base-info
        ref="baseinfocontract"
        :contract="contract"
      />
      <debt-content
        :contract="contract"
      />
    </div>

    <debt-info-by-customer
      :contract="contract"
    />

    <!--        -->
    <Verification
      :contract="contract"
    />

    <consultant-fee
      v-if="isShowConsultantFee"
      :contract="contract"
      :consultant-fee-list="consultantFeeList"
      :consultant-fee-overview="consultantFeeOverview"
      @add-consultant="createConsultant"
      @edit-consultant="updateConsultant"
      @delete-consultant="removeConsultant"
    />

    <debt-collection
      v-if="isShowDebtByStatus"
      :contract="contract"
      :debt-collection-list="debtCollectionList"
      :debt-collection-overview="debtCollectionOverview"
      @add-debt="createDebtCollection"
      @edit-debt="updateDebtCollection"
      @send-deb-sms="handleSendSMS"
      @delete-debt="removeDebtCollection"
    />

    <attachments
      :contract="contract"
      @update-document-files="handleUpdateFileName"
    />

    <!--        -->
    <list-activity
      v-if="ability().can('read', 'Activities')"
      :list-activity="listActivity"
    />

    <comment-sidebar
      :comment="comment"
      @create-comment="createComment"
      @edit-comment="editComment"
      @delete-comment="removeComment"
    />

    <fixed-right-add-files
      v-if="ability().can('add-file', subject('Contract', contract))"
      :document-files.sync="contract.document_files"
      :contract-status="contract.status"
      @update-files="updateDocumentFiles"
    />

    <!--    fixed btn-->
    <fixed-right-button
      :buttons="actionsButton"
      @click="handleActionsButton"
    />

    <b-modal
      id="modal-contract-signed"
      ref="modal-contract-signed"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      :no-close-on-backdrop="true"
      header-text-variant="primary"
      :title="'Cập nhật thông tin cho Hợp Đồng'"
      @ok="updateStatus('contract_signed', {expired_at: getISODate(contractSignedExpiredAt), sign_at: contractSignedDate, real_sign_at: contractSignedDateReal})"
    >
      <b-card-text>
        Để <strong>chuyển trạng thái</strong> sang <span class="text-danger">Hợp đồng đã ký</span> cần bổ
        sung:
        <br>
        <br>
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="mb-1 mb-sm-0"
          >
            <label>Ngày ký HĐ</label>
            <flat-pickr
              v-model="contractSignedDate"
              class="form-control"
              :clearable="true"
              :config="{altFormat: 'd-m-Y', altInput: true, allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: false }"
            />
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <label>Ngày hết hạn HĐ</label>
            <flat-pickr
              v-model="contractSignedExpiredAt"
              class="form-control"
              :clearable="true"
              :config="{altFormat: 'd-m-Y', altInput: true, allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: false }"
            />
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col
            cols="12"
            class="mb-1 mb-sm-0"
          >
            <label>Ngày ký HĐ thực tế</label>
            <flat-pickr
              v-model="contractSignedDateReal"
              class="form-control"
              :clearable="true"
              :config="{altFormat: 'd-m-Y', altInput: true, allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: false }"
            />
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

    <b-modal
      v-if="contract.department"
      id="modal-hand-over"
      ref="modal-hand-over"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      :no-close-on-backdrop="true"
      header-text-variant="primary"
      :title="'Cập nhật thông tin Hợp đồng'"
      @ok="handOverDepartment"
    >
      <b-card-text>
        Để bàn giao <strong :class="'text-'+contract.status">Hợp đồng đã ký</strong> cho đội cần bổ sung:
        <br>
        <br>
        <b-row>
          <b-col
            v-if="contract.department"
            cols="12"
            class="mb-1 mb-sm-0"
          >
            <label>Ngày bàn giao cho <span :class="'text-'+contract.status">{{
              contract.department.name
            }}</span></label>
            <flat-pickr
              v-model="handOverAt"
              class="form-control"
              :clearable="true"
              :config="{altFormat: 'd-m-Y', altInput: true, allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: false }"
            />
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-update-contract-liquidity"
      ref="modal-update-contract-liquidity"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      :no-close-on-backdrop="true"
      header-text-variant="primary"
      :title="'Cập nhật thông tin cho Hợp Đồng đã thanh lý'"
      @ok="updateStatus('liquidity_completed', {liquidity_finish_date : getISODate(contractLiquiditySignedDate), liquidity_result: contractLiquidityResult})"
    >
      <b-card-text>
        Để <strong>chuyển trạng thái</strong> sang <span
          class="text-liquidity_completed"
        >Hợp đồng đã thanh lý</span> cần bổ sung:
        <br>
        <br>
        <label>Ngày ký thanh lý</label>
        <flat-pickr
          v-model="contractLiquiditySignedDate"
          class="form-control"
          :clearable="true"
          :config="{altFormat: 'd-m-Y', altInput: true, allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: false }"
        />
        <br>
        <b-form-group>
          <label for="contractLiquidityResult">Kết quả thực hiện</label>
          <b-form-textarea
            id="contractLiquidityResult"
            v-model="contractLiquidityResult"
            placeholder="Kết quả thực hiện..."
            rows="5"
          />
        </b-form-group>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-edit-sign-date"
      ref="modal-edit-sign-date"
      cancel-title="Hủy"
      ok-title="Cập nhật"
      centered
      :no-close-on-backdrop="true"
      header-text-variant="primary"
      :title="'Cập nhật ngày ký và ngày hết hạn cho HĐ'"
      @ok="updateContractDate(contract)"
    >
      <b-card-text>
        Cập nhật ngày ký và ngày hết hạn cho HĐ:
        <br>
        <br>
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="mb-1 mb-sm-0"
          >
            <label>Ngày ký HĐ</label>
            <flat-pickr
              v-model="contract.sign_at"
              class="form-control"
              :clearable="true"
              :config="{altFormat: 'd-m-Y', altInput: true, allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: false }"
            />
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <label>Ngày hết hạn HĐ</label>
            <flat-pickr
              v-model="contract.expired_at"
              class="form-control"
              :clearable="true"
              :config="{altFormat: 'd-m-Y', altInput: true, allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: false }"
            />
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col
            cols="12"
            class="mb-1 mb-sm-0"
          >
            <label>Ngày ký HĐ thực tế</label>
            <flat-pickr
              v-model="contract.real_sign_at"
              class="form-control"
              :clearable="true"
              :config="{altFormat: 'd-m-Y', altInput: true, allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: false }"
            />
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-confirm-delete-contract"
      ref="modal-confirm-delete-contract"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      :no-close-on-backdrop="true"
      centered
      header-text-variant="danger"
      :title="'Xác nhận xóa' + (contract.contract_code ? ' Hợp đồng: ' : ' Hồ sơ: ') + (contract.contract_code || contract.document_code)"
      @ok="removeContract"
    >
      <b-card-text>
        Bạn thực sự muốn <span class="text-danger">xóa</span>
        {{ (contract.contract_code ? 'Hợp đồng' : 'Hồ sơ') + ' mã số: ' }}
        <span class="text-primary font-weight-bolder">{{ (contract.contract_code || contract.document_code) }}</span> ?
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-assign-team"
      ref="modal-assign-team"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="primary"
      title="Chọn đội mới tiếp nhận hồ sơ"
      :no-close-on-backdrop="true"
      @ok="assignTeamToContract"
    >
      <v-select
        v-model="departmentIdAssigned"
        class="w-100"
        label="name"
        :options="departmentTeam"
        :clearable="false"
        :reduce="val => '' + val.id"
      />
      <br>
      <p>Sau khi <span class="text-primary">Xác nhận</span> Đội tiếp nhận sẽ thay đổi</p>
      <b-badge
        v-if="contract.department"
        :class="'bg-'+contract.status"
      >
        {{ contract.department.name }}
      </b-badge>
      <feather-icon
        v-if="contract.department"
        icon="ArrowRightIcon"
        size="18"
        class="mx-50"
      />
      <b-badge
        v-if="departmentTeam.find(d => d.id == departmentIdAssigned)"
        :class="'bg-danger'"
      >
        {{ departmentTeam.find(d => d.id == departmentIdAssigned).name }}
      </b-badge>
    </b-modal>

    <b-modal
      id="modal-assign-team-to-signed"
      ref="modal-assign-team-to-signed"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="primary"
      title="Chọn đội tiếp nhận Hợp đồng chờ thanh lý"
      :no-close-on-backdrop="true"
      @ok="changeTeamToContract"
    >
      <b-row>
        <b-col
          cols="12"
          sm="7"
        >
          Chọn đội
          <v-select
            v-model="departmentIdAssigned"
            class="w-100 mb-1"
            label="name"
            :options="departmentTeam"
            :clearable="false"
            :reduce="val => '' + val.id"
          />
        </b-col>
        <b-col
          cols="12"
          sm="5"
        >
          <b-form-group>
            <label
              class="mb-0"
              for="amount_for_team_percent"
            >% Chi đội</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                id="amount_for_team_percent"
                v-model="contract.amount_for_team_percent"
                placeholder="% Chi đội"
                type="number"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <br>
      <p>Sau khi đội mới tiếp nhận, Hợp đồng sẽ chuyển trạng thái</p>
      <b-badge :class="'bg-' + contract.status">
        {{ $status.getName(contract.status) }}
      </b-badge>
      <feather-icon
        icon="ArrowRightIcon"
        size="18"
        class="mx-50"
      />
      <b-badge class="bg-contract_signed">
        {{ $status.getName('contract_signed') }}
      </b-badge>
    </b-modal>

    <b-modal
      id="modal-change-creator"
      ref="modal-change-creator"
      cancel-title="Hủy"
      ok-title="Đổi"
      centered
      header-text-variant="primary"
      :title="'Thay đổi NV PKH cho Hồ sơ ' + contract.document_code"
      :no-close-on-backdrop="true"
      @ok="changeCreatorToContract"
    >
      Chọn nhân viên Phòng khách hàng
      <v-select
        v-model="creatorIdAssigned"
        class="w-100 mb-1"
        label="fullname"
        :options="usersPKH"
        :clearable="false"
        :reduce="val => '' + val.id"
      />
      <p>Nhân viên tiếp nhận Hồ sơ sẽ bị thay đổi:</p>
      <b-badge class="bg-entry_new">
        {{ contract.creator && contract.creator.fullname }}
      </b-badge>
      <feather-icon
        icon="ArrowRightIcon"
        size="18"
        class="mx-50"
      />
      <b-badge
        v-if="users.find(item => item.id.toString() === creatorIdAssigned)"
        class="bg-contract_signed"
      >
        {{ users.find(item => item.id.toString() === creatorIdAssigned).fullname }}
      </b-badge>
    </b-modal>

    <b-modal
      id="modal-verify-approving"
      ref="modal-verify-approving"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      size="lg"
      centered
      header-text-variant="primary"
      title="Điền thông tin xác minh"
      :no-close-on-backdrop="true"
      @ok="verifyInfoToContract"
    >
      <b-form>
        <b-form-group>
          <label for="verify_info">Nội dung điều tra xác minh:</label>
          <b-form-textarea
            id="verify_info"
            v-model="verifyContractData.verify_info"
            rows="5"
            placeholder="Nôi dung xác minh"
          />
        </b-form-group>
        <b-form-group>
          <label for="verify_duration">Dự kiến thời gian thu hồi:</label>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="verify_duration"
              v-model="verifyContractData.verify_duration"
              type="number"
              placeholder="Nhập số tháng"
            />
            <b-input-group-append is-text>
              tháng
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group>
          <label for="verify_suggestion_status">Đề xuất trạng thái tiếp theo:</label>
          <b-form-radio-group
            v-model="verifyContractData.verify_suggestion_status"
            :options="[{text: 'Đề nghị ký HĐ', value: 'agree'}, {text: 'Trả Hồ Sơ', value: 'deny'}]"
            name="verify_suggestion_status-validation"
          />
        </b-form-group>
        <b-form-group>
          <label for="authenticator_ids">Nhân viên thực hiện xác minh:</label>
          <v-select
            id="authenticator_ids"
            v-model="verifyContractData.authenticator_ids"
            class="w-100"
            multiple
            label="fullname"
            :options="contractMembers"
            :reduce="val => '' + val.id"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-confirm-change-status"
      ref="modal-confirm-change-status"
      cancel-title="Hủy"
      :no-close-on-backdrop="true"
      ok-title="Xác nhận"
      centered
      header-text-variant="primary"
      title="Xác nhận chuyển trạng thái!"
      @ok="changeStatusTo(currentAction)"
    >
      <b-card-text>
        <span :class="'text-' + contract.status">
          {{ contract.contract_code ? 'Hợp đồng' : 'Hồ sơ' }}
        </span>
        mã số
        <span :class="'text-' + contract.status">
          {{ contract.contract_code || contract.document_code }}
        </span>
        thay đổi trạng thái
        <br>
        <br>
        <b-badge :class="'bg-' + contract.status">
          {{ $status.getName(contract.status) }}
        </b-badge>
        <feather-icon
          icon="ArrowRightIcon"
          size="18"
          class="mx-50"
        />
        <b-badge :class="'bg-' + currentAction">
          {{ $status.getName(currentAction) }}
        </b-badge>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-handle-revoke-team"
      ref="modal-handle-revoke-team"
      cancel-title="Hủy"
      :no-close-on-backdrop="true"
      ok-title="Xác nhận"
      centered
      header-text-variant="primary"
      title="Xác nhận thu hồi!"
      @ok="handleRevokeTeam"
    >
      <b-card-text>
        <span :class="'text-' + contract.status">
          Hồ sơ
        </span>
        mã số
        <span :class="'text-' + contract.status">
          {{ contract.document_code }}
        </span>
        sẽ được thu hồi!
        <br>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-download-template"
      ref="modal-download-template"
      cancel-title="Hủy"
      size="xl"
      ok-title="Tải xuống"
      :no-close-on-backdrop="true"
      centered
      header-text-variant="primary"
      :title="'Chọn mẫu văn bản tải xuống cho ' + (contract.contract_code ? 'Hợp đồng [' : 'Hồ sơ [') + (contract.contract_code || contract.document_code) + ']'"
      @ok="downloadMultiTemplate"
    >
      <b-card-text>
        <b-form-group>
          <b-form-checkbox-group
            id="checkbox-group-2"
            v-model="selectedTemplateToDownload"
            name="selected template to download"
            class="w-100 d-flex flex-wrap"
          >
            <b-row class="w-100">
              <b-col
                v-for="item in templates"
                :key="'template download' + item.id"
                cols="12"
                md="6"
                xl="4"
              >
                <b-form-checkbox
                  :value="item.id"
                >
                  {{ item.name }}
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form-checkbox-group>
        </b-form-group>
      </b-card-text>
    </b-modal>
  </div>
  <not-permission v-else />
</template>

<script>
import {
  BBadge,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  VBToggle, BRow, BCol,
  BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'

import NotPermission from '@core/components/NotPermission.vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import contractStoreModule from '@/views/contracts/contractStoreModule'
import commentStoreModule from '@/views/comment/comentStoreModule'
import http from '@/global/http'
import useContractsCreate from '@/views/contracts/create/useContractsCreate'
import FixedRightButton from '@core/components/fixed-button/Fixed-Right-Button.vue'
import FixedRightAddFiles from '@/views/contracts/detail/FixedRightAddFiles.vue'
import demartmentStoreModule from '@/views/settings/departments/departmentStoreModule'
import vSelect from 'vue-select'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'
import Verification from '@/views/contracts/detail/Verification.vue'
import templateStoreModule from '@/views/settings/templates/templateStoreModule'
import ConsultantFee from '@/views/contracts/detail/ConsultantFee.vue'
import DebtContent from './DebtContent.vue'
import DebtInfoByCustomer from './DebtInfoByCustomer.vue'
import TopInfo from './TopInfo.vue'
import Attachments from './Attachments.vue'
import BaseInfo from './BaseInfo.vue'
import CommentSidebar from './Comment-Sidebar.vue'
import DebtCollection from './DebtCollection.vue'
import ListActivity from './ListActivity.vue'

export default {
  components: {
    ListActivity,
    DebtContent,
    BaseInfo,
    DebtInfoByCustomer,
    TopInfo,
    NotPermission,
    BInputGroup,
    BInputGroupAppend,
    BBadge,
    Attachments,
    BCardText,
    CommentSidebar,
    ConsultantFee,
    FixedRightButton,
    BForm,
    BFormGroup,
    Verification,
    BFormTextarea,
    FixedRightAddFiles,
    BFormInput,
    DebtCollection,
    BFormRadioGroup,
    flatPickr,
    vSelect,
    BRow,
    BCol,
    BFormCheckboxGroup,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      comment: [],
      currentCommentId: '',
      currentAction: '',
      ability,
      subject,
    }
  },
  computed: {
    isShowDebtByStatus() {
      return !['entry_new', 'verify_new', 'verify_approving', 'verify_deny'].includes(this.contract.status)
    },
    isShowConsultantFee() {
      return !['entry_new'].includes(this.contract.status)
    },
    currentComment() {
      if (!this.currentCommentId || !this.comment || !this.comment.length) return {}
      const found = this.comment.find(item => item.id.toString() === this.currentCommentId) || {}
      return found || {}
    },
    actionsButton() {
      const c = this.contract
      const approvedRole = ability()
        .can('approved', subject('Contract', c))
      const specialActions = []

      //
      if (approvedRole) {
        const agreeText = c.verify_suggestion_status === 'agree' ? 'Duyệt - Đề nghị ký' : 'Duyệt - Trả hồ sơ'
        const denyText = c.verify_suggestion_status === 'agree' ? 'Từ chối duyệt' : 'Từ chối duyệt'

        specialActions.push(
          {
            label: denyText,
            icon: 'XIcon',
            value: 'deny',
          }, {
            label: agreeText,
            icon: 'CheckIcon',
            suggest: true,
            value: 'agree',
          },
        )
      }

      //
      if (ability().can('assigned_to_team', subject('Contract', c))) {
        specialActions.push({
          value: 'is_hand_over',
          label: 'Bàn giao đội - Ký HĐ',
          icon: 'NavigationIcon',
        })
      }

      //
      if (ability()
        .can('revoke-team', subject('Contract', c))) {
        specialActions.push({
          value: 'revoke-team',
          label: 'Thu hồi đội',
          icon: 'RotateCcwIcon',
        })
      }

      //
      if (ability()
        .can('change-department', subject('Contract', c))) {
        specialActions.push({
          value: 'change-department-to-signed',
          label: 'Chuyển đội - Ký HĐ',
          icon: 'UsersIcon',
        })
      }

      //
      if (ability()
        .can('change-creator', subject('Contract', c))) {
        specialActions.push({
          value: 'change-creator',
          label: 'Đổi NVPKH - Bàn giao',
          icon: 'UserIcon',
        })
      }

      //
      if (ability()
        .can('edit', subject('Contract', c))) {
        specialActions.push({
          label: 'Chỉnh sửa',
          icon: 'EditIcon',
          value: 'edit',
          suggest: !approvedRole,
        })
      }

      //
      if (ability()
        .can('download_document', subject('Contract', c))) {
        specialActions.push(
          {
            label: 'Tải bản in',
            icon: 'DownloadIcon',
            value: 'download-template',
          },
        )
      }

      //
      if (ability()
        .can('download_request_ticket', subject('Contract', c))) {
        specialActions.push(
          {
            label: 'Tải phiếu yêu cầu',
            icon: 'DownloadIcon',
            value: 'download-request-ticket',
          },
        )
      }

      //
      if (this.contract.contract_code && ability()
        .can('update_contract_date', 'Contract')) {
        specialActions.push({
          label: 'Sửa ngày ký',
          icon: 'EditIcon',
          value: 'edit-sign-date',
        })
      }

      //
      if (ability()
        .can('delete', 'Contract')) {
        specialActions.push({
          label: 'Xóa',
          icon: 'DeleteIcon',
          value: 'delete',
        })
      }

      return [
        ...this.nextStatus(c),
        ...specialActions,
      ]
    },
  },
  watch: {
    '$route.fullPath': {
      handler() {
        this.fetchData()
      },
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    handOverDepartment() {
      this.$action(this.handleOver({
        id: this.contract.id,
        is_hand_over: true,
        hand_over_at: this.handOverAt,
      }, res => {
        Object.assign(this.contract, res.data)
      }), true)
    },
    handleUpdateFileName() {
      this.$action(http.api(store.dispatch('contract/updateDocumentFilesName', {
        contract_id: this.contract.id,
        document_files: this.contract.document_files,
      })), true)
    },
    fetchData() {
      this.fetchCurrentContract()
      this.fetchCurrentDepartments()
      this.routeParamsId = this.$route.params.id
      this.fetchDebtCollection(this.$route.params.id)
      this.fetchConsultantFee(this.$route.params.id)
    },
    updateHasPlan(isHasPlan) {
      this.$action(http.api(store.dispatch('contract/updateHasPlan', {
        id: this.contract.id,
        is_has_plan: isHasPlan,
      }), () => {
        this.contract.is_has_plan = isHasPlan
      }), true)
    },
    removeComment(id) {
      this.$action(http.api(store.dispatch('comment/deleteComment', {
        id,
      }), () => {
        this.comment = this.comment.filter(item => item.id.toString() !== id.toString())
      }), true)
    },
    createDebtCollection(data) {
      this.$action(this.addDebtCollection(data), true)
    },
    updateDebtCollection(data) {
      this.$action(this.editDebtCollection(data), true)
    },
    handleSendSMS(data) {
      this.$action(this.sendDebSMS(data, res => {
        if (res && res.data) {
          this.fetchDebtCollection(this.$route.params.id)
        }
      }), true)
    },
    removeDebtCollection(data) {
      this.$action(this.deleteDebtCollection(data), true)
    },
    createConsultant(data) {
      this.$action(this.addConsultantFee(data), true)
    },
    updateConsultant(data) {
      this.$action(this.editConsultantFee(data), true)
    },
    removeConsultant(data) {
      this.$action(this.deleteConsultantFee(data), true)
    },
    updateDocumentFiles(files) {
      this.$action(http.api(store.dispatch('contract/updateDocumentFiles', {
        contractId: this.contract.id,
        documentFiles: files,
      }), res => {
        this.contract.document_files = res.data.document_files
      }), true)
    },
    updateContractCode() {
      this.$action(http.api(store.dispatch('contract/updateContractCode', {
        contract_code: this.contractSignedContractCode,
        appendix_code: this.appendixCode,
        contractId: this.contract.id,
      }), res => {
        Object.assign(this.contract, res.data)
      }), true)
    },
    updateContractDate(contract) {
      this.$action(http.api(store.dispatch('contract/updateContractDate', {
        id: contract.id,
        sign_at: contract.sign_at,
        real_sign_at: contract.real_sign_at,
        expired_at: contract.expired_at,
        reply_at: contract.reply_at,
        hand_over_at: contract.hand_over_at,
        verify_duration: contract.verify_duration,
      }), res => {
        Object.assign(this.contract, res.data)
      }), true)
    },
    changeStatusTo(status) {
      if (status === 'contract_denied') {
        this.updateStatus(status)
      }
      if (status === 'liquidity_processing') {
        this.updateStatus(status)
      }
      if (status === 'liquidity_completed') {
        this.updateStatus(status)
      }
      if (status === 'contract_open') {
        this.updateStatus(status)
      }
    },
    handleActionsButton(btn) {
      this.currentAction = btn.value
      if (btn.value === 'edit') {
        this.goEditContract()
        return
      }
      if (btn.value === 'revoke-team') {
        this.openModalHandleRevokeTeam()
      }
      if (btn.value === 'is_hand_over') {
        this.openModalHandOver()
      }
      if (btn.value === 'download-template') {
        this.openModalDownloadTemplate()
      }
      if (btn.value === 'download-request-ticket') {
        this.openModalDownloadTemplate('ticket')
      }
      if (btn.value === 'delete') {
        this.openModalConfirmDeleteContract()
      }
      if (btn.value === 'verify_new') {
        this.openModalAssignTeam()
      }
      if (btn.value === 'verify_approving') {
        this.openModalVerifyApproving()
      }
      if (btn.value === 'agree' || btn.value === 'deny') {
        this.offerToSignContract(btn.value)
      }
      if (btn.value === 'contract_signed') {
        this.showModal('modal-contract-signed')
      }
      if (btn.value === 'change-department-to-signed') {
        this.openModalChangeDepartmentToSigned()
      }
      if (btn.value === 'change-creator') {
        this.openModalChangeCreator()
      }
      if (btn.value === 'liquidity_completed') {
        this.openModalUpdateContractLiquidity()
      }
      if (btn.value === 'edit-sign-date') {
        this.openModalEditSignDate()
      }
      if (['contract_open', 'contract_denied', 'liquidity_processing'].includes(btn.value)) {
        this.$refs['modal-confirm-change-status'].show()
      }
    },
    fetchCurrentDepartments() {
      this.$action(http.api(store.dispatch('department/fetchDepartments'), res => {
        this.departmentList = res.data
      }))
    },
    fetchCurrentContract() {
      this.$action(this.fetchContract(this.$route.params.id))
        .then(data => {
          if (this.$refs.baseinfocontract) {
            this.$refs.baseinfocontract.setSameHeight()
          }
          this.fetchComments()
          const departmentId = data.department?.id
          if (!departmentId) return
          this.fetchDepartment(departmentId)
          const d = new Date()
          const preMonth = d.getMonth() < 9 ? '0' : ''
          const preDate = d.getDate() < 9 ? '0' : ''
          this.contractSignedDate = `${d.getFullYear()}-${preMonth}${d.getMonth() + 1}-${preDate}${d.getDate()}`
          this.handOverAt = `${d.getFullYear()}-${preMonth}${d.getMonth() + 1}-${preDate}${d.getDate()}`
          const ed = new Date(new Date().setMonth(new Date().getMonth() + Number.parseInt(data.verify_duration || 0, 10)))
          const preExpireMonth = ed.getMonth() < 9 ? '0' : ''
          this.contractSignedExpiredAt = `${ed.getFullYear()}-${preExpireMonth}${ed.getMonth() + 1}-${ed.getDate()}`

          this.fetchActivities({
            contract_id: this.contract.id,
          })
        })
    },
    fetchDepartment(id) {
      this.$action(http.api(store.dispatch('department/fetchDepartment', { id }), res => {
        this.contractMembers = res.data.users || []
      }))
    },
    updateStatus(status, options = {}) {
      const data = {
        id: this.contract.id,
        status,
        ...options,
      }
      this.$action(this.updateContractStatus(data), true)
        .then(() => {
          this.fetchCurrentContract()
        })
    },
    offerToSignContract(status) {
      const { id } = this.contract
      this.$action(http.api(store.dispatch('contract/approve', {
        status,
        id,
      }), () => {
        this.fetchCurrentContract()
      }), true)
    },
    openModalVerifyApproving() {
      this.showModal('modal-verify-approving')
    },
    verifyInfoToContract(event) {
      event.preventDefault()
      if (!this.verifyContractData.verify_info) {
        this.$toastr('Chưa nhập nội dung xác minh', 'danger')
        return
      }
      if (!this.verifyContractData.verify_duration && this.verifyContractData.verify_duration !== 0) {
        this.$toastr('Chưa nhập thời gian thu hồi', 'danger')
        return
      }
      if (!this.verifyContractData.verify_suggestion_status) {
        this.$toastr('Chưa chọn đề xuất trạng thái tiếp theo', 'danger')
        return
      }
      if (!this.verifyContractData.authenticator_ids || !this.verifyContractData.authenticator_ids.length) {
        this.$toastr('Chưa chọn nhân viên thực hiện xác minh', 'danger')
        return
      }
      const data = {
        id: this.$route.params.id,
        verifyData: Object.assign(this.verifyContractData, {
          verify_duration: parseFloat(this.verifyContractData.verify_duration) || 0,
        }),
      }
      this.$action(this.verificationReport(data), true)
        .then(() => {
          this.fetchCurrentContract()
          this.hideModal('modal-verify-approving')
        })
    },
    removeContract() {
      this.$action(this.deleteContract(this.contract.id), true)
        .then(() => {
          this.$router.push({ name: 'contracts-list' })
        })
    },
    fetchComments() {
      this.$action(store.dispatch('comment/fetchCommentByContract', { id: this.contract.id }))
        .then(res => {
          this.comment = res.data.reverse()
        })
    },
    editComment({
      content,
      type,
      id,
    }) {
      const data = {
        content,
        type,
        id,
        full_name: getUserData().fullname,
        user_id: getUserData().id,
        contract_id: this.contract.id,
      }
      this.$action(store.dispatch('comment/updateComment', data), true)
        .then(res => {
          Object.assign(this.comment.find(c => c.id === res.data.id), res.data)
        })
    },
    createComment({
      content,
      type,
    }) {
      const data = {
        content,
        type,
        full_name: getUserData().fullname,
        user_id: getUserData().id,
        contract_id: this.contract.id,
      }
      this.$action(store.dispatch('comment/createComment', data), true)
        .then(res => {
          this.comment.unshift(res.data)
        })
    },
    goEditContract() {
      this.$router.push({
        name: 'contracts-edit',
        params: { id: this.contract.id },
      })
    },
    openModalAssignTeam() {
      this.$refs['modal-assign-team'].show()
    },
    openModalChangeDepartmentToSigned() {
      this.contract.amount_for_team_percent = this.contract.amount_for_team_percent || 40
      this.$refs['modal-assign-team-to-signed'].show()
    },
    openModalChangeCreator() {
      this.fetchUsers()
      this.$refs['modal-change-creator'].show()
    },
    openModalUpdateContractLiquidity() {
      this.$refs['modal-update-contract-liquidity'].show()
    },
    openModalEditSignDate() {
      this.$refs['modal-edit-sign-date'].show()
    },
    handleRevokeTeam() {
      this.$action(this.revokeTeamContract(this.contract.id), true)
        .then(() => {
          this.fetchCurrentContract()
        })
    },
    downloadTemplate(contractId, templateId) {
      http.api(store.dispatch('template/renderTemplate', {
        contract_id: parseFloat(contractId),
        template_id: parseFloat(templateId),
      }), res => {
        this.openLinkInNewTab(res.data)
      })
    },
    downloadMultiTemplate() {
      if (this.selectedTemplateToDownload && this.selectedTemplateToDownload.length) {
        for (let i = 0; i < this.selectedTemplateToDownload.length; i += 1) {
          const templateId = this.selectedTemplateToDownload[i]
          this.downloadTemplate(this.downloadTemplateData.contract_id, templateId)
        }
      }
    },
    assignTeamToContract() {
      const data = {
        id: this.$route.params.id,
        department_id: parseFloat(this.departmentIdAssigned),
      }
      this.$action(this.assignTeam(data), true)
        .then(res => {
          console.log(res)
          this.fetchCurrentContract()
        })
    },
    changeCreatorToContract() {
      const data = {
        id: this.$route.params.id,
        creator_id: parseFloat(this.creatorIdAssigned),
      }
      this.$action(this.changeCreator(data), true)
        .then(res => {
          console.log(res)
          this.fetchCurrentContract()
        })
    },
    changeTeamToContract() {
      const data = {
        id: this.$route.params.id,
        department_id: parseFloat(this.departmentIdAssigned),
        amount_for_team_percent: Number.parseInt(this.contract.amount_for_team_percent, 10),
      }
      this.$action(this.assignTeam(data), true)
        .then(res => {
          console.log(res)
          this.fetchCurrentContract()
        })
    },
    openModalDownloadTemplate(onlyType) {
      this.fetchTemplates(onlyType)
      this.$refs['modal-download-template'].show()
    },
    openModalHandleRevokeTeam() {
      this.$refs['modal-handle-revoke-team'].show()
    },
    openModalHandOver() {
      this.$refs['modal-hand-over'].show()
    },
    openModalConfirmDeleteContract() {
      this.$refs['modal-confirm-delete-contract'].show()
    },
    fetchTemplates(onlyType) {
      this.$action(http.api(store.dispatch('template/fetchTemplates'), res => {
        const templates = res.data || []
        const templatesFilterByContract = templates.filter(item => {
          if (onlyType === 'ticket') return true
          // nếu có giấy giới thiệu thì hiện ra nếu khớp với đội của hợp đồng hiện tại (bất kể là cá nhân hay công ty)
          if (this.createHandle(item.name)
            .includes('giay-gioi-thieu')) {
            return this.createHandle(item.name)
              .includes(this.contract.department.code.toLowerCase())
          }
          // nếu ko phải loại Cá nhân hay Công ty thì hiện ra vì là bản in dành cho tất cả
          if (!this.createHandle(item.name)
            .includes('ca-nhan') && !this.createHandle(item.name)
            .includes('cong-ty')) {
            return true
          }
          if (this.createHandle(item.name)
            .includes(this.contract.debtor.category === 'personal' ? 'ca-nhan' : 'cong-ty')) {
            return true
          }
          // nếu ko thì ko hiện ra
          return false
        })
        this.templates = onlyType ? templatesFilterByContract.filter(t => t.type === onlyType) : templatesFilterByContract
      }))
    },
  },
  setup() {
    const CONTRACT_STORE_MODULE_NAME = 'contract'
    const COMMENT_STORE_MODULE_NAME = 'comment'
    const DEPARTMENT_STORE_MODULE_NAME = 'department'
    const TEMPLATE_STORE_MODULE_NAME = 'template'
    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.registerModule(CONTRACT_STORE_MODULE_NAME, contractStoreModule)
    if (!store.hasModule(COMMENT_STORE_MODULE_NAME)) store.registerModule(COMMENT_STORE_MODULE_NAME, commentStoreModule)
    if (!store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.registerModule(DEPARTMENT_STORE_MODULE_NAME, demartmentStoreModule)
    if (!store.hasModule(TEMPLATE_STORE_MODULE_NAME)) store.registerModule(TEMPLATE_STORE_MODULE_NAME, templateStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.unregisterModule(CONTRACT_STORE_MODULE_NAME)
      if (store.hasModule(COMMENT_STORE_MODULE_NAME)) store.unregisterModule(COMMENT_STORE_MODULE_NAME)
      if (store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENT_STORE_MODULE_NAME)
      if (store.hasModule(TEMPLATE_STORE_MODULE_NAME)) store.unregisterModule(TEMPLATE_STORE_MODULE_NAME)
    })

    return {
      ...useContractsCreate(),
    }
  },
}
</script>
<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';

.detail-title {
  font-weight: bold;
  margin-bottom: .5rem;
  margin-top: 1.5rem;
  position: relative;

  &:first-child {
    margin-top: unset;
  }
}

.detail-label {
  margin-right: 1rem;
  margin-bottom: .5rem;
}

.detail-text {
}

.base-info {
  display: grid;
  grid-template-columns: 60% 40%;
  column-gap: .5rem;
}

@media (max-width: 992px) {
  .base-info {
    grid-template-columns: 1fr;
  }
}
</style>
